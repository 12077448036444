import './PrivacyPolicy.css'
import React, { useEffect, useRef } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

function PrivacyPolicy() {
  
  const TopContainer=useRef();
  useEffect(()=>{
      TopContainer.current.scrollIntoView({block:"end",behavior:"smooth"})
  },[])

  return (
    <>
    <div ref={TopContainer}/>
    <div>
      <Navbar/>
      <div className='about'>
        <h1>Privacy Policy</h1>
        <p >All your pictures are kept safe and away from any harm and once your order is completed your pictures will be deleted</p>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default PrivacyPolicy
