import { useSelector } from 'react-redux';
import './FetchingOrdersAdmin.css'
import React, { useEffect, useState, useRef } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function FetchingOrdersAdmin() {
  const TopContainer=useRef();
    useEffect(()=>{
      TopContainer.current.scrollIntoView({block:"end",behavior:"smooth"})
        ordersFetching();
    },[])
    const [orders,setOrders]=useState([]);
    const tokenA=useSelector(state=>state.authTokenA);
    const ordersFetching= async ()=>{
        const response = await fetch("https://backend-j6gf.onrender.com/api/orderAdminSide/fetchOrders", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "auth-token":tokenA
             },
          });
          
          const json= await response.json();
          setOrders(json);
        }
        const settingState= async ()=>{
            const response = await fetch(`https://backend-j6gf.onrender.com/api/orderAdminSide/editStatus/${IdOfOrder}`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  "auth-token":tokenA
                 },
                 body: JSON.stringify({status}),
              });
              
              handleClose();
            }

        const [show, setShow] = useState(false);
        
        const [status, setSts] = useState("Order approved!");

        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
        const statusChange=()=>{
            var e = document.getElementById("sts");
            var selectedOption = e.options[e.selectedIndex].text;
            setSts(selectedOption);
          }
          
          const [IdOfOrder,setIdOfOrder]=useState();
          const clickingEdit=(idd)=>{
            handleShow();
            setIdOfOrder(idd);
          }


  return (
    <div>
       <div ref={TopContainer}/>
      <Modal className='modl2' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <div>
         <h2  className='modlh2'><strong>Order status:</strong></h2>
        <select className='modl-inp2' id="sts" onChange={statusChange}>   
        <option>Order approved</option>
        <option>Order declined</option>
        <option>In making process</option>
        <option>Order dispatched</option>
        <option>Delivered</option>
        </select>
         </div>
        </Modal.Body>
        <Modal.Footer className='modlh2'>
          <Button className='modlh2' variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button className='modlh2' variant="primary" onClick={settingState}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {orders.map((element)=>{
        return <div className='orderDetails' key={element._id}>
            
            <p><b>Order ID: </b>{element._id}</p>
            <p><b>Customer ID: </b>{element.user}</p>
            <p><b>Total Bill: </b>{element.totalBill}</p>
            <p><b>Product Name: </b>{element.productName}</p>
            <p><b>Price: </b>{element.price}</p>
            <p><b>Size: </b>{element.size}</p>
            <p><b>Expected delivery dates :  </b>{element.startDate}{element.endDate}</p>
            <p><b>Gift text: </b>{element.giftText}</p>
            <p><b>Description provided by customer: </b>{element.description}</p>
            <p><b>Address: </b>{element.address}</p>
            <p><b>Phone: </b>{element.phone}</p>
            <p><strong>Facial details customer wants to include: </strong>{element.facial}</p>
            <p><strong>Design : </strong>{element.design}</p>
            <p><strong>Border : </strong>{element.border}</p>
            <p><strong>Text which is provided by customer to add on the product : </strong>{element.text}</p>
            
            <p><strong>Images provided by customer:</strong></p>
            {element.images.map((el)=>{
                return<div className='ie'>
                    {el}
                </div>
            })}

            <p><strong>Current Status of order: </strong><i style={{cursor:'pointer'}} onClick={()=>{clickingEdit(element._id)}} class="mx-3 fa-solid fa-pen-to-square"></i><br/>{element.currentStatus}</p>
            <hr/><hr/>
        </div>
      })}
    </div>
  )
}

export default FetchingOrdersAdmin
