import Context from '../Context/Context'
import './BuyingDetails.css'
import Rating from '@mui/material/Rating';
import React, { useContext, useEffect, useState, useRef } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BreadcrumbItem } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {actionCreators} from './state/index'
import { useDispatch } from 'react-redux';
import Navb from './Navbar';
import Footer from './Footer';

function BuyingDetails() {
  const TopContainer=useRef();
  useEffect(()=>{
    TopContainer.current.scrollIntoView({block:"end",behavior:"smooth"})
    datesFetching();
  },[])

    const [dates,setDates]=useState({"startDate":"","endDate":""})
  const datesFetching= async ()=>{
    const response = await fetch("https://backend-j6gf.onrender.com/api/buyingProducts/gettingDates", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
         },
      });
      
      const json= await response.json();
      setDates({"startDate":json.startDate,"endDate":json.endDate})
    }



  const navigate=useNavigate();
    const token=useSelector(state=>state.authToken)
    const log=useSelector(state=>state.log)
    const name=useSelector(state=>state.name)
    const price=useSelector(state=>state.price)
    const image=useSelector(state=>state.image)
    const numberOfRating=useSelector(state=>state.nor)
    const rating=useSelector(state=>state.rating)
    const category=useSelector(state=>state.ctg)
    const id=useSelector(state=>state.id)
  const ordersSetter=useDispatch();
  const orders=bindActionCreators(actionCreators,ordersSetter);
  const limit = useSelector(state=>state.orders);
    

      const p= useContext(Context);

      const [files, setFiles] = useState(Array.from({ length: 7 }, () => null));
      const [description,setDescription]=useState("");
      const [text,setText]=useState("");
      const [address,setAddress]=useState("");
      const [facial,setFacial]=useState("Nose only");
      const [phoneNumber,setPhoneNumber]=useState(0);
      const [confirm,setConfirm]=useState(true);
      const [added,setAdded]=useState(false);
      const [statePrice,setStatePrice]=useState(1000);
      const [border,setBorder]=useState("Black");
      const [design,setDesign]=useState("Plain");
      const [size,setSize]=useState("4*4");
      const [gift,setGift]=useState(false);
      const [pdf,setPdf]=useState("");
      const [giftText,setGiftText]=useState("");
     
      const handleFileChange = (index, e) => {
        const newFiles = [...files];
        newFiles[index] = e.target.files[0];
        setFiles(newFiles);
      };

      const priceFunction=()=>{
        var e = document.getElementById("price");
        var selectedOption = e.options[e.selectedIndex].text;
        setSize(selectedOption);
        if(selectedOption==='4*4')
        {
          setStatePrice(1000)
        }
        else if(selectedOption==='4*6')
        {
          setStatePrice(1200)
        }
        else if(selectedOption==='6*6')
        {
          setStatePrice(1500)
        }
        else if(selectedOption==='6*8')
        {
          setStatePrice(1800)
        }
        else if(selectedOption==='5*7')
        {
          setStatePrice(1500)
        }
        else if(selectedOption==='5*10')
        {
          setStatePrice(1800)
        }
        else if(selectedOption==='8*8')
        {
          setStatePrice(2000)
        }
        else if(selectedOption==='8*10')
        {
          setStatePrice(2200)
        }
        else if(selectedOption==='10*12')
        {
          setStatePrice(2600)
        }
        else if(selectedOption==='11*14')
        {
          setStatePrice(3500)
        }
        else if(selectedOption==='12*15')
        {
          setStatePrice(4000)
        }
        else if(selectedOption==='16*20')
        {
          setStatePrice(4800)
        }
        else if(selectedOption==='20*24')
        {
          setStatePrice(5500)
        }
      }
    
      const borderChange=()=>{
        var e = document.getElementById("border");
        var selectedOption = e.options[e.selectedIndex].text;
        setBorder(selectedOption);
      }
      const facialChange=()=>{
        var e = document.getElementById("facial");
        var selectedOption = e.options[e.selectedIndex].text;
        setFacial(selectedOption);
      }
      const designChange=()=>{
        var e = document.getElementById("design");
        var selectedOption = e.options[e.selectedIndex].text;
        setDesign(selectedOption);
      }


      const radioChecker=()=>{
        if(document.getElementById('flexRadioDefault1').checked===true)
        {
          setGift(true)
          setGiftText("Default text");
        }else if(document.getElementById('flexRadioDefault1').checked===false)
        {
          setGift(false)
          setGiftText("");
        }
      }

      const pdfChecker=()=>{
        if(document.getElementById('pdf').checked===true)
        {
          setPdf("true")
        }else if(document.getElementById('pdf').checked===false)
        {
          setPdf("")
        }
      }

        const submit = async event => {
          event.preventDefault()
         
          const formData = new FormData()
          for(let m=0;m<files.length;m++)
          {
            formData.append("photos", files[m]);
          }

          if(description)
          {
          formData.append("description",description)
          }
          formData.append("address",address)
          formData.append("phoneNumber",phoneNumber)
          formData.append("facial",facial);
          formData.append("text",text);
          if(gift)
          {
          formData.append("giftText",giftText);
          }
          formData.append("pdf",pdf);
          if(category==="Acrylic Frames")
          {
            formData.append("design",design);
            formData.append("border",border);
            formData.append("size",size);
            if(pdf)
            {
              formData.append("price",1500)
            }
            else{
              formData.append("price",statePrice)
            }
          }else{
            if(pdf)
            {
              formData.append("price",1500)
            }
            else{
              formData.append("price",price)
            }
          }

          if(log===true)
          {
          const result = await axios.post(`https://backend-j6gf.onrender.com/api/buyingProducts/addingToCart/${id}`, formData, { headers: {'Content-Type': 'multipart/form-data','auth-token':token}}) 
        
          if(result.status!==200)
          {

          }else{
            datesFetching();
            setAdded(true);
            setTimeout(() => {
              setAdded(false);
            }, 1000);
          }
          }
          else{
            setConfirm(false);
          }
        }

  return (
    <>
    <div style={{'marginLeft':'2vw','marginRight':'2vw'}}>
      <div ref={TopContainer}/>
      <Navb/>
      <Breadcrumb className='crumb'>
      <BreadcrumbItem href="/" style={{textDecoration:'none'}}>Home</BreadcrumbItem>
      <BreadcrumbItem href={`/category/${category}`} >
        {category}
      </BreadcrumbItem>
      <Breadcrumb.Item active>{name}</Breadcrumb.Item>
    </Breadcrumb>
      <div className='rw'>
        <></>
      <div id="carouselExampleControlsNoTouching" class="sld carousel slide" data-bs-touch="true">
    <div class="carousel-inner">
    {image[0]?<div class="carousel-item active">
      <img src={require(`../Product images/${image[0]}`)} class="ig bd-placeholder-img bd-placeholder-img-lg d-block w-100"  alt="..."/>
    </div>:""}
    {image[1]?<div class="carousel-item">
      <img src={require(`../Product images/${image[1]}`)} class="ig bd-placeholder-img bd-placeholder-img-lg d-block w-100"  alt="..."/>
    </div>:""}    
    {image[2]?<div class="carousel-item">
      <img src={require(`../Product images/${image[2]}`)} class="ig bd-placeholder-img bd-placeholder-img-lg d-block w-100"  alt="..."/>
    </div>:""}
    {image[3]?<div class="carousel-item">
      <img src={require(`../Product images/${image[3]}`)} class="ig bd-placeholder-img bd-placeholder-img-lg d-block w-100"  alt="..."/>
    </div>:""}
    {image[4]?<div class="carousel-item">
      <img src={require(`../Product images/${image[4]}`)} class="ig bd-placeholder-img bd-placeholder-img-lg d-block w-100"  alt="..."/>
    </div>:""}
    {image[5]?<div class="carousel-item">
      <img src={require(`../Product images/${image[5]}`)} class="ig bd-placeholder-img bd-placeholder-img-lg d-block w-100"alt="..."/>
    </div>:""}
    {image[6]?<div class="carousel-item">
      <img src={require(`../Product images/${image[6]}`)} class="ig bd-placeholder-img bd-placeholder-img-lg d-block w-100"  alt="..."/>
    </div>:""}
    {image[7]?<div class="carousel-item">
      <img src={require(`../Product images/${image[7]}`)} class="ig bd-placeholder-img bd-placeholder-img-lg d-block w-100"  alt="..."/>
    </div>:""}
    {image[8]?<div class="carousel-item">
      <img src={require(`../Product images/${image[8]}`)} class="ig bd-placeholder-img bd-placeholder-img-lg d-block w-100"  alt="..."/>
    </div>:""}
    {image[9]?<div class="carousel-item">
      <img src={require(`../Product images/${image[9]}`)} class="ig bd-placeholder-img bd-placeholder-img-lg d-block w-100" alt="..."/>
    </div>:""}
    {image[10]?<div class="carousel-item">
      <img src={require(`../Product images/${image[10]}`)} class="ig bd-placeholder-img bd-placeholder-img-lg d-block w-100" alt="..."/>
    </div>:""}
    {image[11]?<div class="carousel-item">
      <img src={require(`../Product images/${image[11]}`)} class="ig bd-placeholder-img bd-placeholder-img-lg d-block w-100" alt="..."/>
    </div>:""}
    {image[12]?<div class="carousel-item">
      <img src={require(`../Product images/${image[12]}`)} class="ig bd-placeholder-img bd-placeholder-img-lg d-block w-100"  alt="..."/>
    </div>:""}
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<div className='info'>
  <h2 className='nm'>{pdf?"PDF of faceless potrait":name}</h2>
  <div ><Rating name="half-rating-read" defaultValue={rating} precision={0.1} readOnly /><span style={{marginBottom:'12vw'}}>{rating     }{`   (${numberOfRating})`}</span></div>
  {pdf?<h3 className='hd my-3'><strong>Rs 1500</strong></h3>:category!=="Acrylic Frames"?<h3 className='hd my-3'><strong>Rs {price}</strong></h3>:<><h3 className='hd my-3'><strong>Rs {statePrice}</strong></h3> <div className='my-2'>
    <h5 >Select size of frame</h5>
        <select className='dw' id="price" onChange={priceFunction}>   
        <option>4*4</option>
        <option>4*6</option>
        <option>6*6</option>
        <option>6*8</option>
        <option>5*7</option>
        <option>5*10</option>
        <option>8*8</option>
        <option>8*10</option>
        <option>10*12</option>
        <option>11*14</option>
        <option>12*15</option>
        <option>16*20</option>
        <option>20*24</option>
        </select>
  </div></>}
  <h4 className='hd my-3'><strong>Category: </strong>{category}</h4>
  <div className='my-4'>
    <h4 className='hd'><strong>Product Description:</strong></h4>
    <p>Searching for something unique that can present your happy picture into something memorable or  <strong>dont have a picture together with your loved ones?!</strong><br/><br/> Well no worries!<br/>Your search for a perfect customise gift is over now, Presenting {category}, a perfect gift for any occasion that can convert your happy moments into a happy stand piece.<br/><br/>Let us make your events more memorable and preserve your memories to cherish them forever and ever!<br/><br/><strong>Note:<br/>No more than 7 images can be added in one frame.</strong></p>
  </div>
</div>
</div>
<input class="chk form-check-input  my-5" type="checkbox" name="flexRadioDefault" onChange={pdfChecker} id="pdf"/>
<label class="gft form-check-label my-5" htmlFor="pdf">Only PDF of faceless potrait</label>
            {/* UserData */}
    <h3 className='hd mx-4'><strong>Insert images:</strong></h3>
    <form className='frm' onSubmit={submit}>
      {/* <div className='inputs'>
        <input className='files my-4' filename={file} onChange={e => setFile(file=>[...file,e.target.files[0]])} type="file" accept="image/*"/>
        <input filename={file} className='files my-4' onChange={e => setFile(file=>[...file,e.target.files[0]])} type="file" accept="image/*"/>
        <input filename={file} className='files my-4' onChange={e => setFile(file=>[...file,e.target.files[0]])} type="file" accept="image/*"/>
        <input filename={file} className='files my-4' onChange={e => setFile(file=>[...file,e.target.files[0]])} type="file" accept="image/*"/>
        <input filename={file} className='files my-4' onChange={e => setFile(file=>[...file,e.target.files[0]])} type="file" accept="image/*"/>
        <input filename={file} className='files my-4' onChange={e => setFile(file=>[...file,e.target.files[0]])} type="file" accept="image/*"/>
        <input filename={file} className='files my-4' onChange={e => setFile(file=>[...file,e.target.files[0]])} type="file" accept="image/*"/>
        </div> */}

<div className='inputs'>
      {files.map((file, index) => (
        <input
          key={index}
          className='files my-4'
          onChange={(e) => handleFileChange(index, e)}
          type="file"
          accept="image/*"
        />
      ))}
    </div>

        <div className='addToCart'>
        {pdf?<></>:<><div class="form-check">
          <input class="chk form-check-input mx-4 my-5" type="checkbox" name="flexRadioDefault" onChange={radioChecker} id="flexRadioDefault1"/>
          <label class="gft form-check-label my-5" htmlFor="flexRadioDefault1">Add gift wrap & card +Rs 130</label>
          <div style={{display:gift?"":'none'}}>
          <h5 className='desch my-3'><strong>Enter text you want to write on gift card</strong></h5>
          <input className='desc'  onChange={e => setGiftText(e.target.value)} type="text"/>
          </div>
          
        </div></>}
        <div className='my-5'>
    <h5 className='desch'><strong>Select facial attributes you want to include</strong></h5>
        <select className='dw' id="facial" onChange={facialChange}>   
        <option>Nose only</option>
        <option>Lips only</option>
        <option>Eyebrows only</option>
        <option>Nose and Lips</option>
        <option>Nose and Eyebrows</option>
        <option>Eyebrows and Lips</option>
        <option>None (Faceless)</option>
        <option>Nose, Eyebrows and Lips</option>
        </select>
         </div>
         {category==="Acrylic Frames"?<><div className='my-3'>
    <h5 className='desch'><strong>Frame borders</strong></h5>
        <select className='dw' id="border" onChange={borderChange}>   
        <option>Black</option>
        <option>White</option>
        <option>Beige</option>
        <option>Brown</option>
        </select>
         </div>
         <div className='my-3'>
         <h5 className='desch'><strong>Designing</strong></h5>
             <select className='dw' id="design" onChange={designChange}>   
             <option>Plain</option>
             <option>Borders with design</option>
             </select>
              </div></>:<></>}
        {pdf?<></>:<><h5 className='desch my-3'><strong>Text you want to add on {name}</strong></h5>
        <input className='desc' onChange={e => setText(e.target.value)} type="text"/>
        <h5 className='desch my-3'><strong>Description</strong></h5>
        <input className='desc' onChange={e => setDescription(e.target.value)} type="text"/></>}
        <h5 className='desch my-3'><strong>Adderss</strong></h5>
        <input className='desc' onChange={e => setAddress(e.target.value)} type="text" required/>
        <h5 className='desch my-3'><strong>Contact number</strong></h5>
        <input className='number' onChange={e => setPhoneNumber(e.target.value)} type="number" required/>
        <p className='expDate'><strong>Expected delivery date: {dates.startDate} - {dates.endDate}</strong></p>
    {!confirm?navigate('/login'):<></>}
      {added?<Alert variant="filled" className='alrt my-2' severity="success">
        <p>Item added to cart!</p>
      </Alert>:<></>}
      {files.every(file => file === null) ? (
  <Alert variant="filled" className='alrt my-2' severity="info">
    <p className='heightIssue'>Insert your image in order to add this product in your cart!</p>
  </Alert>
) : (
  <button className='my-2 bn btn btn-success' type="submit">Add to cart</button>
)}

        
        </div>
      </form>
    </div>
      <Footer/>
      </>
  )
}

export default BuyingDetails
