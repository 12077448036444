export const loginChecker=(status)=>{
    return (stateSetter)=>{
        stateSetter({
            type:'log',
            payload:status
    })
    }
}
export const loginCheckerA=(status)=>{
    return (stateSetterA)=>{
        stateSetterA({
            type:'logA',
            payload:status
    })
    }
}

export const setAuthToken=(token)=>{
    return (authSetter)=>{
        authSetter({
            type:'token',
            payload:token
    })
    }
}
export const setAuthTokenA=(tokenA)=>{
    return (authSetterA)=>{
        authSetterA({
            type:'tokenA',
            payload:tokenA
    })
    }
}

export const setName=(name)=>{
    return (nameSetter)=>{
        nameSetter({
            type:'name',
            payload:name
    })
    }
}

export const setPrice=(price)=>{
    return (priceSetter)=>{
        priceSetter({
            type:'price',
            payload:price
    })
    }
}

export const setImage=(image)=>{
    return (imageSetter)=>{
        imageSetter({
            type:'image',
            payload:image
    })
    }
}

export const setNOR=(nor)=>{
    return (norSetter)=>{
        norSetter({
            type:'nor',
            payload:nor
    })
    }
}

export const setrating=(rating)=>{
    return (ratingSetter)=>{
        ratingSetter({
            type:'rating',
            payload:rating
    })
    }
}

export const setctg=(ctg)=>{
    return (ctgSetter)=>{
        ctgSetter({
            type:'ctg',
            payload:ctg
    })
    }
}

export const setID=(ID)=>{
    return (idSetter)=>{
        idSetter({
            type:'id',
            payload:ID
    })
    }
}

export const setOrders=(orders)=>{
    return (ordersSetter)=>{
        ordersSetter({
            type:'orders',
            payload:orders
    })
    }
}

export const setTotalBill=(bill)=>{
    return (billSetter)=>{
        billSetter({
            type:'bill',
            payload:bill
    })
    }
}