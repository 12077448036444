import React, { useEffect, useState, useRef } from 'react'
import './AdminPortal.css'
import NavbA from './NavbA';
import ProductsA from './ProductsA';
import { useSelector } from 'react-redux';


function AdminPortal() {

  const [produts,setProducts]=useState([]);
  const TopContainer=useRef();
  const tokenA=useSelector(state=>state.authTokenA);
  
  const allProducts = async () => {
    const response = await fetch("https://backend-j6gf.onrender.com/api/products/fetchAllProducts", {
        method: "GET", 
        headers: {
          "auth-token":tokenA
         },
      });
      
      const json= await response.json();
      
      if(json.success)
      {
        setProducts(json.products);
      
      }     
    };


  useEffect(()=>{
    allProducts();
    TopContainer.current.scrollIntoView({block:"end",behavior:"smooth"})
    
  },[])

  

  return (
    <div>
       <div ref={TopContainer}/>
      <NavbA/>
    <div>
      <h2 className='productHeadings' style={{fontWeight:'700'}}>All Products</h2>
      <div className='row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4 my-3'>
      {produts.map((element)=>{
        return <div className='spread col my-3'  key={element._id}>
            <ProductsA name={element.name} price={element.price} image={element.images} AggregatedRating={element.AggregatedRating} numberOfRatingsGiven={element.numberOfRatingsGiven} category={element.category} _id={element._id}/>
        </div>
      })}
      </div>
    </div>
    </div>
    
    
  )
}

export default AdminPortal
