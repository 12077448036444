import { combineReducers } from "redux";
import logReducer from "./logReducer";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from "redux-persist";
import authReducer from "./authReducer";
import nameReducer from "./nameReducer";
import priceReducer from "./priceReducer";
import imageReducer from "./imageReducer";
import norReducer from "./NOR";
import ratingReducer from "./ratings";
import categoryReducer from "./category";
import idReducer from "./id";
import ordersReducer from "./ordersReducer";
import billReducer from "./billReducer";
import authReducerA from "./authA";
import reducerA from "./logAReducer";
import reducer from "./logReducer";

const reducers=combineReducers({
    log:reducer,
    logA:reducerA,
    authToken:authReducer,
    authTokenA:authReducerA,
    name:nameReducer,
    price:priceReducer,
    image:imageReducer,
    nor:norReducer,
    rating:ratingReducer,
    ctg:categoryReducer,
    id:idReducer,
    orders:ordersReducer,
    totalBill:billReducer
})

const persistConfig = {
    key: 'root',
    storage
  }

  const persistedReducer =persistReducer(persistConfig,reducers);

export default persistedReducer;