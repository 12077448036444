import React, {useState } from 'react'
import './Signup.css'
import {useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert';
import { bindActionCreators } from 'redux';
import {actionCreators} from './state/index'
import { useDispatch } from 'react-redux';

function Signup() {

  const logSetter=useDispatch();
  const action=bindActionCreators(actionCreators,logSetter);
  const authSetter=useDispatch();
  const token=bindActionCreators(actionCreators,authSetter);
  

    const navigate=useNavigate();
    const [data,setData]=useState({"email":"","password":""})
    const [status,setStatus]=useState("");
    const [confirm,setConfirm]=useState(true)


    const signup = async (email, password) => {
        const response = await fetch("https://backend-j6gf.onrender.com/api/auth/signup", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
           },
          body: JSON.stringify({ email,password}),
        });
    
        const json= await response.json();
      if(!json.success)
      {
          setStatus(json.message);
          setConfirm(false);
          
          setTimeout(() => {
            setConfirm(true);
          }, 1500);


      }else{
        setConfirm(true);
        token.setAuthToken(json.authToken);
        action.loginChecker(true);
        navigate("/");
      }    
    };
  
  const onchange=(e)=>{
      setData({...data,[e.target.name]:e.target.value})       
  }

  const submit=(e)=>{
      e.preventDefault();
      signup(data.email,data.password);        
  }



  return (
    <>
    {!confirm?<Alert className='alrt' variant="filled" severity="error">
        <p>{status}</p>
      </Alert>:<></>}
        <div className='OutestDiv'>
        <form className="form_main" onSubmit={submit} action="">
        <p className="headin">Signup</p>
        <div className="inputContainer">
        
       
        <input placeholder="Email" id="email" name='email' className="inputField" type="email" onChange={onchange} required/>
        </div>
    
        <div className="inputContainer">
            
            
            <input placeholder="Password" id="password" name='password' className="inputField" type="password" onChange={onchange} minLength={5} required/>
        </div>
              
        <button id="button" className='button' >Submit</button>
            
        </form>

    </div>
    </>
  )
}

export default Signup
