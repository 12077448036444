import { Route, Routes } from "react-router-dom";
import Home from './Components/Home'
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import ContextState from "./Context/ContextStates";
import BuyingDetails from "./Components/BuyingDetails";
// import Navb from "./Components/Navbar";
import CategoryWiseProduct from "./Components/CategoryWiseProduct";
import Cart from "./Components/Cart";
import Status from "./Components/Status";
import ChangePassword from "./Components/ChangePassword";
import AdminLogin from "./Components/AdminLogin";
import AdminPortal from "./Components/AdminPortal";
import FetchingOrdersAdmin from "./Components/FetchingOrdersAdmin";
import FetchingPayments from "./Components/FetchingPayments";
import AboutUs from "./Components/AboutUs";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import ReturnPolicy from "./Components/ReturnPolicy";
import ContactUs from "./Components/ContactUs";



function App() {
  return (
    <>
    <ContextState>
    
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/login" element={<Login/>}></Route>
        <Route path="/signup" element={<Signup/>}></Route>
        <Route path="/buyingDetails" element={<BuyingDetails/>}></Route>
        <Route path="/category/Islamic Standees" element={<CategoryWiseProduct category={"Islamic Standees"}/>}></Route>
        <Route path="/category/Couple Standees" element={<CategoryWiseProduct category={"Couple Standees"}/>}></Route>
        <Route path="/category/Family Standees" element={<CategoryWiseProduct category={"Family Standees"}/>}></Route>
        <Route path="/category/Acrylic Frames" element={<CategoryWiseProduct category={"Acrylic Frames"}/>}></Route>
        <Route path="/cart" element={<Cart/>}></Route>
        <Route path="/OrdersStatus" element={<Status/>}></Route>
        <Route path="/changePassword" element={<ChangePassword/>}></Route>
        <Route path="/1@!2!@@!12Admin" element={<AdminLogin/>}></Route>
        <Route path="/AdminPortal" element={<AdminPortal/>}></Route>
        <Route path="/ordersFetching" element={<FetchingOrdersAdmin/>}></Route>
        <Route path="/payments" element={<FetchingPayments/>}></Route>
        <Route path="/AboutUs" element={<AboutUs/>}></Route>
        <Route path="/returnPolicy" element={<ReturnPolicy/>}></Route>
        <Route path="/privacyPolicy" element={<PrivacyPolicy/>}></Route>
        <Route path="/contactUs" element={<ContactUs/>}></Route>
      </Routes>
    </ContextState>
    </>
  );
}

export default App;
