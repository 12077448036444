import './FetchingPayments.css'
import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';

function FetchingPayments() {
  const TopContainer=useRef();
    useEffect(()=>{
      TopContainer.current.scrollIntoView({block:"end",behavior:"smooth"})
        ordersFetching();
    },[])
    const [orders,setOrders]=useState([]);
   

    const tokenA=useSelector(state=>state.authTokenA);
    
          
        const ordersFetching= async ()=>{
            const response = await fetch("https://backend-j6gf.onrender.com/api/orderAdminSide/fetchPayments", {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  "auth-token":tokenA
                 },
              });
              
              const json= await response.json();
              setOrders(json);
            }



  return (
    <div>
      <div ref={TopContainer}/>
      {orders.map((element)=>{
        return <div className='orderDetails' key={element._id}>
            
            <p><b>Payment ID: </b>{element._id}</p>
            <p><b>Customer ID: </b>{element.user}</p>
            <p><b>Total Bill: </b>{element.totalBill}</p>
            <p><b>Product Name: </b></p>
            {element.productsNames.map((el)=>{
                return<div className='ie'>
                    {el}
                </div>
            })}
            <p><b>Payment Screenshot: </b>{element.ss}</p>
            <p><b>Payment method: </b>{element.paymentMethod}</p>
            <p><b>Delivery charges :  </b>{element.deliveryCharges}</p>
             <hr/><hr/>
        </div>
      })}
    </div>
  )
}

export default FetchingPayments
