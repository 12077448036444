import './Products.css'
import Rating from '@mui/material/Rating';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import {actionCreators} from './state/index'

function Products(props) {
  const nameSetter=useDispatch();
  const name=bindActionCreators(actionCreators,nameSetter);
  const priceSetter=useDispatch();
  const price=bindActionCreators(actionCreators,priceSetter);
  const imageSetter=useDispatch();
  const image=bindActionCreators(actionCreators,imageSetter);
  const norSetter=useDispatch();
  const nor=bindActionCreators(actionCreators,norSetter);
  const ratingSetter=useDispatch();
  const rating=bindActionCreators(actionCreators,ratingSetter);
  const ctgSetter=useDispatch();
  const category=bindActionCreators(actionCreators,ctgSetter);
  const idSetter=useDispatch();
  const id=bindActionCreators(actionCreators,idSetter);

  const setState=()=>{
   

    name.setName(props.name);
    price.setPrice(props.price);
    image.setImage(props.image);
    nor.setNOR(props.numberOfRatingsGiven);
    rating.setrating(props.AggregatedRating.toFixed(1));
    category.setctg(props.category);
    id.setID(props._id);

  }
  return (
    <Link to={'/buyingDetails'} onClick={setState} style={{textDecoration:'none'}}>
    <div>
          <div class="card mx-4">
          <div class="card-img">
            <img className='img' src={require(`../Product images/${props.image[0]}`)} alt="" ></img>
          </div>
          <div class="card-info">
            <p class="text-title">{props.name}</p>
            <div className='ratings' ><Rating className='stars' defaultValue={props.AggregatedRating.toFixed(1)} precision={0.1} readOnly /><span className='nor'>{props.AggregatedRating.toFixed(1)     }</span></div>
          </div>
          <div class="card-footer">
          <span class="text-title2">{`Rs ${props.price}`}</span>
        </div>
        </div>
    </div>
    </Link>
   
  )
}

export default Products;
