import { useSelector } from 'react-redux';
import './Status.css'
import React, { useState, useEffect, useRef } from 'react'
import Rating from '@mui/material/Rating';

function Status() {
  
  const [status,setStatus]=useState([]);
  const token = useSelector(state=>state.authToken);
  const TopContainer=useRef();
  useEffect(()=>{
    TopContainer.current.scrollIntoView({block:"end",behavior:"smooth"})
    statusFetching();
  },[])
  

  const statusFetching= async ()=>{
    const response = await fetch("https://backend-j6gf.onrender.com/api/orderAdminSide/userOrders", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token":token
         },
      });
      
      const json= await response.json();
      setStatus(json);
    }

    const ratings= async (pdName,rating)=>{
      const response = await fetch(`https://backend-j6gf.onrender.com/api/products/ratings/${pdName}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "auth-token":token
           },
           body: JSON.stringify({rating}),
        });
        statusFetching();
      }

      const delOrder= async (id)=>{
        const response = await fetch(`https://backend-j6gf.onrender.com/api/products/delete/${id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "auth-token":token
             }
          }); 
        }

const funcCall=(pdName,rating,id)=>{
  ratings(pdName,rating);
  delOrder(id);
}


  return (
    <div>
      <div ref={TopContainer}/>
      {status.length>0?
       <div className='parentDivs'>
    <div className='cartCards'>
      {status.map((element)=>{
        return <div key={element._id}>
        <div className='productLists'>
        <div className='namess'>
          {element.productName}
        </div>
        <div className='pricess'>
          {element.price}
        </div>
        <div className='currentStatuss'>
          Status: {element.currentStatus}
        </div>
      </div>
      <div className='deliveryDatess'>Expected delivery date: <p>{element.startDate} - {element.endDate}</p></div>
      {element.currentStatus==="Delivered"?<div className='feedback mx-5'><p>Give us your feedback!</p>
      <Rating
        name="simple-controlled"
        value={0}
        precision={0.1}
        size='large'
        onChange={(event, newValue) => {
          funcCall(element.productName,newValue,element._id);
        }}
      />
      </div>:<></>}
      <hr/>
      </div>
      })}
    </div>
    </div>:<div><h3 className='empty'>You haven't ordered anything yet!</h3></div>
}
    </div>
  )
}

export default Status
