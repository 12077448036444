import './ContactUs.css'
import React, { useEffect, useRef } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import coverImage from '../Website media/cover image.png'
import { Link } from 'react-router-dom'
import wa from '../Website media/WhatsAppButtonGreenSmall.png'
function ContactUs() {
  const TopContainer=useRef();
    useEffect(()=>{
        TopContainer.current.scrollIntoView({block:"end",behavior:"smooth"})
    },[])
  return (
    <>
    <div>
    <div ref={TopContainer}/>
      <Navbar/>
      <div className='abot' style={{backgroundImage: `url(${coverImage})`,
            height: "100%",
            width:"100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"}}>
        <h1>Contact Us</h1>
        {/* <h4><strong>Whatsapp us at</strong></h4>
        <p><strong><i class="fa-brands fa-whatsapp mx-2"></i>03332058023</strong></p> */}
        <Link aria-label="Chat on WhatsApp" to="https://wa.me/923332058023"> <img className='wa' alt="Chat on WhatsApp" src={wa} /></Link>
        <h4><strong>Our Email</strong></h4>
        <p><strong><i class="fa-regular fa-envelope mx-2"></i>picture.says.it.all1@gmail.com</strong></p>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default ContactUs
