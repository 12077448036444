import './AboutUs.css'
import React, { useEffect, useRef } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
function AboutUs() {
  const TopContainer=useRef();
  useEffect(()=>{
      TopContainer.current.scrollIntoView({block:"end",behavior:"smooth"})
  },[])
  return (
    <>
    <div ref={TopContainer}/>
    <div>
      <Navbar/>
      <div className='about'>
        <h1>About</h1>
        <p >Hey all! I am the artist and full time owner behind "Picture says it all" something I started in lockdown 2020, with Arabic calligraphy, paintings and with the intension of never stopping it. In search of figuring out my style, I finally converted my art page into a small business or as you can say a shop where you can get unique, custom and handmade gifts for your loved ones.</p>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default AboutUs
