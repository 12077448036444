import { useSelector } from 'react-redux';
import './Cart.css'
import React, { useEffect, useState, useRef } from 'react'
import { bindActionCreators } from 'redux';
import {actionCreators} from './state/index'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import axios from 'axios';

function Cart() {

  const [promoEntered,setPromoEntered]=useState(false);
  const [promo,setPromo]=useState("");
  const navigate=useNavigate();
  const token = useSelector(state=>state.authToken);
  const [cart,setCart]=useState([]);
  const ordersSetter=useDispatch();
  const orders=bindActionCreators(actionCreators,ordersSetter);
  const billSetter=useDispatch();
  const totalBill=bindActionCreators(actionCreators,billSetter);
  const totalBillState=useSelector(state=>state.totalBill)
  const [mode,setMode]=useState("")
  const [file,setFile]=useState()
  const [bought,setBought]=useState(false)
  const [bought2,setBought2]=useState(false)
  const [deliveryCharges,setDeliveryCharges]=useState("no")
  const TopContainer=useRef();
  useEffect(()=>{
    cartFetching();
    TopContainer.current.scrollIntoView({block:"end",behavior:"smooth"})
    
  },[])
  

  const cartFetching= async ()=>{
    const response = await fetch("https://backend-j6gf.onrender.com/api/buyingProducts/cartDisplaying", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token":token
         },
      });
      
      const json= await response.json();
      orders.setOrders(json.cart.length);
      setCart(json.cart);
      totalBill.setTotalBill(json.totalAmount);
    }

    const deleteCart= async (id,price)=>{
      const response = await fetch(`https://backend-j6gf.onrender.com/api/buyingProducts/deleteFromCart/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "auth-token":token
           },
        });
        cartFetching();
      }


      const radioChecker=()=>{
        if(document.getElementById('flexRadioDefault1').checked)
        {
          setMode(document.getElementById('flexRadioDefault1').value)
          
        }
        else if(document.getElementById('flexRadioDefault2').checked)
        {
          setMode(document.getElementById('flexRadioDefault2').value)
          
        }
      }
      
      const promoFunc=()=>{
        if(promoEntered===false)
        {
          if(document.getElementById('promo').value==="THANKYOUOFF10")
          {
            const temp=totalBillState-(totalBillState*10/100)
            totalBill.setTotalBill(temp);
            setPromoEntered(true);
          }
        }
      }
      const onchange=(e)=>{
        setPromo(e.target.value)

      }
      const confirmPayment=async ()=>{

        if(totalBillState<3000 && totalBillState>0)
          {
            const temp=totalBillState+250;
            totalBill.setTotalBill(temp);
            setDeliveryCharges("yes");
          }else{
            setDeliveryCharges("no");
          }
        setBought2(true);
        const formData = new FormData();
        formData.append("paymentMethod",mode);
        formData.append("paymentScreenShot",file);
        formData.append("deliveryCharges",deliveryCharges);
        formData.append("totalBill",totalBillState);
        
      

      const result = await axios.post("https://backend-j6gf.onrender.com/api/buyingProducts/orderConfirmed", formData, { headers: {'Content-Type': 'multipart/form-data','auth-token':token}}) 
        
          if(result.status!==200)
          {
          }else{
            
            setBought(true);

            setTimeout(() => {
              setBought(false);
              setBought2(false);
              setPromoEntered(false);
              totalBill.setTotalBill(0);
              navigate('/');
            }, 5000);
          }
        }          



  return (
    <>
     <div ref={TopContainer}/>
    {totalBillState===0?<div>
        <h2 className='empty'>Your cart is empty :(</h2>
    </div>:
    <div className='parentDiv'>
    <div className='cartCard'>
      {cart.map((element)=>{
        return <div key={element._id}>
        <div className='productList'>
        <div className='names'>
          {element.productName}
        </div>
        <div className='prices'>
          {element.price}
        </div>
        <div>
           <i class="deleteIcon fa-solid fa-trash" onClick={()=>{deleteCart(element._id,element.price)}}></i>
        </div>
      </div>
      <hr/>
      </div>
      })}
      
      
      {totalBillState<3000 && totalBillState>0?<h6 className='totalBill' style={{display:'flex', justifyContent:'center'}}  >Delivery charges:Rs 250</h6>:<></>}
      <div className='totalBill my-4'>
      <h4 className='mx-2'>Subtotal: </h4>
      <h4>Rs {totalBillState<3000 && totalBillState>0?totalBillState+250:totalBillState}</h4>
      </div>
      <hr/>
      <div className='promo'>
        <h4>Have a promo code?</h4>
        <input placeholder='Promo code' id='promo' name='promo' type='text' onChange={onchange} value={promo}/>
        <button className='btn btn-primary mx-2 promoBtn' onClick={promoFunc}>Apply code</button>
      </div>
      <div className='modeOfPayment'>
        <div class="form-check">
          <strong><h5 className='paymentOpt' style={{fontWeight:'bold'}}>Select payment option</h5></strong>
          <input class="chck form-check-input my-2" value={"100% online payment"} type="radio" name="flexRadioDefault" onChange={radioChecker} id="flexRadioDefault1"/>
          <label class="form-check-label my-2" htmlFor="flexRadioDefault1">
            100% online payment
          </label>
        </div>
        <div class="form-check">
          <input class="chck form-check-input my-2" type="radio" value={"30%-50% advance online payment and remaining amount will be paid through Cash On Delivery."} name="flexRadioDefault" onChange={radioChecker}         id="flexRadioDefault2"/>
          <label class="form-check-label my-2" htmlFor="flexRadioDefault2">
            30%-50% advance online payment and remaining amount will be paid through Cash On Delivery.
          </label>
        </div>
      </div>
      <div className='accounts'>
        <p><strong>0337 0365503<br/>Muhammad Ali<br/>JazzCash<br/><br/>FAISAL BANK LIMITED<br/>IBB JODIA BAZAR, KARACHI<br/>ACC NO: 3010301900137846<br/>IBAN NO: PK17FAYS3010301900137846<br/>ACC NAME: MUHAMMAD ALI</strong></p>
      </div>
      <hr/>
      <div className='ssInput my-4'>
        <p className='paymentHeading'>Insert screenshot of your payment for verification</p>
      <input className='files' filename={file} onChange={(e) => {e.preventDefault();setFile(e.target.files[0]);}}  type="file" accept="image/*" required/>
      <div>
      {bought?<Alert className='successAlert my-2' variant="filled" severity="success">
        <p><strong>Congratulations!<br/>Now you can check status of your order.</strong><br/> You are one step closer to your personalized standee in just few days.<br/>Your order is confirmed and has been processed to next stage.<br/>Thank you for trusting us!</p>
      </Alert>:<></>}
      </div>
      {mode===""?<><Alert variant="filled" className='alrt my-2' severity="info">
    <p>Select any payment method above!</p>
  </Alert></>:bought2?<button className="paymentButton btn btn-success my-3" disabled>Processing...</button>:<button className={`paymentButton btn btn-success my-3 ${!file?'d-none':""}`} onClick={confirmPayment}>Confirm</button>}
      </div>
      

    </div>
    </div>}
    </>
    )
}

export default Cart
