import React, { useEffect, useState, useRef } from 'react'
import Products from './Products';
import './CategoryWiseProduct.css'
import Navb from './Navbar';
import Footer from './Footer';

function CategoryWiseProduct(props) {


    const [products,setProduct]=useState([]);

    let category=props.category;

    const categoryFetching=async ()=>{
        const response = await fetch(`https://backend-j6gf.onrender.com/api/products/specificCategoryProduct/${category}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                 },
              });
              
              const json= await response.json();
              setProduct(json);
    }
    const TopContainer=useRef();
      useEffect(()=>{
        TopContainer.current.scrollIntoView({block:"end",behavior:"smooth"})
        categoryFetching()
      },[])


  return (
    <div>
      <div ref={TopContainer}/>
      <Navb/>
      <h1 className='categHeading '><strong>{props.category}</strong></h1>
      <div className='row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4 my-2'>
      {products.map((element)=>{
        return <div className='spread col my-3' key={element._id}>
            <Products name={element.name} price={element.price} image={element.images} AggregatedRating={element.AggregatedRating} numberOfRatingsGiven={element.numberOfRatingsGiven} category={element.category} _id={element._id}/>
        </div>
      })}
      </div>
      <Footer/>
    </div>
  )
}

export default CategoryWiseProduct
