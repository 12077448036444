import Footer from './Footer'
import Navbar from './Navbar'
import './ReturnPolicy.css'
import React, { useEffect, useRef } from 'react'

function ReturnPolicy() {
  const TopContainer=useRef();
    useEffect(()=>{
        TopContainer.current.scrollIntoView({block:"end",behavior:"smooth"})
    },[])
  return (
      <>
    <div ref={TopContainer}/>
    <div>
      <Navbar/>
      <div className='about'>
        <h1>Return Policy</h1>
        <p >As our products are fully customized so we show you the process at every stage of its creation and once it is delivered to you it is non-returnable</p>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default ReturnPolicy
