import React, { useState } from "react";
import Context from './Context';

const ContextState = (props) => { 

    const [product,setProduct]=useState({"name":"","price":"","image":[],"AggregatedRating":0,"numberOfRatingsGiven":0,"category":"","_id":""})

    return (
    <Context.Provider value={{product,setProduct}}>
      {props.children}
    </Context.Provider>
  );
};

export default ContextState;
