import React, { useEffect, useState, useRef } from 'react'
import Image from 'react-bootstrap/Image';
import coverImage from '../Website media/cover image.png'
import delivery from '../Website media/free shipping.jpg'
import heart from '../Website media/handmade with love.jpg'
import customization from '../Website media/customizable.jpg'
import islamic from '../Product images/Islamic Standee/10 5.jpeg'
import couple from '../Product images/Couple Standee/6 7.jpeg'
import family from '../Product images/Family Standee/8 3.jpeg'
import frames from '../Product images/Acrylic Frames/3.jpeg'
import './Home.css'
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Products from './Products';
import Navb from './Navbar';
import vid1 from '../Website media/Home page Video.mov'

function Home() {

  const [produts,setProducts]=useState([]);

  const bestSelling = async () => {
    const response = await fetch("https://backend-j6gf.onrender.com/api/products/bestSellingProducts", {
        method: "GET"
      });
      
      const json= await response.json();
      
      if(json.success)
      {
        setProducts(json.products);
      }     
    };

    const TopContainer=useRef();
  useEffect(()=>{
    bestSelling();
    TopContainer.current.scrollIntoView({block:"end",behavior:"smooth"})
  },[])


   

  return (
    <div>
      <div ref={TopContainer}/>
      <Navb/>
      <div >
      <Image src={coverImage}  fluid/>
      </div>
      <div className='cards' >
            <div className="card1 my-5 mx-5">
              <Image className='card_img1'src={delivery} alt=''></Image>
              <div className="card__descr-wrapper1">
          <p className="card__title1" style={{color:'lightcoral'}}>
          Free shipping
        </p>
        <p className="card__descr1" style={{fontFamily:'Roboto'}}>
          Get free shipping all over Pakistan on orders above <strong>3000 pkr</strong>
        </p>
        </div>
      </div>
      <div className="card2 my-5 mx-5">
              <Image className='card_img2'src={heart}></Image>
              <div className="card__descr-wrapper2">
          <p className="card__title2"  style={{color:'lightcoral'}}>
          Handmade with love
        </p>
        <p className="card__descr2" style={{fontFamily:'Roboto'}}>
         Each standee and frame is made with full of love, care and hard work. Each piece has it own touch of uniqueness. They may not be perfect but definitely perfect enough to bring a smile on your loved one's faces.
        </p>
        </div>
      </div>
      <div className="card3 my-5 mx-5">
              <Image className='card_img3'src={customization} alt=''></Image>
              <div className="card__descr-wrapper3">
          <p className="card__title3"  style={{color:'lightcoral'}}>
          Customization
        </p>
        <p className="card__descr3" style={{fontFamily:'Roboto'}}>
          <strong>Don't have a picture together with your loved ones and still want a standee?</strong>Well, we are here to customize it for you, and with many other requirements you want to get fulfilled.
        </p>
        </div>
      </div>
      </div>

    {/* After cards */}

    {/* <video src={require('../Product images/')}  width="600" height="300" controls="controls" autoplay="true" /> */}

    <div className='vid'>
    <video className='vd' src={vid1} autoPlay loop muted/>
    </div>
    <div>
      <h2 className='categoryHeading my-5'  style={{color:'lightcoral'}}>Categories</h2>
    </div>
    <div className='categories my-5'>
      <div className='category1'>
        <Link to={'/category/Islamic Standees'} style={{textDecoration:'none',fontFamily:'Roboto'}}>
        <Image className='categImage'src={islamic} alt=''></Image>
        <h1 className='catButtons' style={{display:'flex',justifyContent:'center',alignItems:'center'}}>Islamic Standees</h1>
        </Link>
      </div>
      <div className='category2'>
        <Link to={'/category/Couple Standees'} style={{textDecoration:'none',fontFamily:'Roboto'}}>
        <img className='categImage' id='couple' src={couple} alt=''></img>
        <h1 className='catButtons' style={{display:'flex',justifyContent:'center',alignItems:'center'}}>Couple Standees</h1>
        </Link>
      </div>
      <div className='category3'>
      <Link to={'/category/Family Standees'} style={{textDecoration:'none',fontFamily:'Roboto'}}>
        <Image className='categImage'src={family} alt=''></Image>
        <h1 className='catButtons' style={{display:'flex',justifyContent:'center',alignItems:'center'}}>Family Standees</h1>
        </Link>
      </div>
      <div className='category4'>
      <Link to={'/category/Acrylic Frames'} style={{textDecoration:'none',fontFamily:'Roboto'}}>
        <Image className='categImage'src={frames} alt=''></Image>
        <h1 className='catButtons' style={{display:'flex',justifyContent:'center',alignItems:'center'}}>Acrylic Frames</h1>
        </Link>
      </div>
    </div>

    <div>
      <h2 className='productHeading ' style={{color:'lightcoral'}}>Best selling products</h2>
      <div className='row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4 my-3'>
      {produts.map((element)=>{
        return <div className='spread col my-3'  key={element._id}>
            <Products name={element.name} price={element.price} image={element.images} AggregatedRating={element.AggregatedRating} numberOfRatingsGiven={element.numberOfRatingsGiven} category={element.category} _id={element._id}/>
        </div>
      })}
      </div>
    </div>
    <Footer/>
    </div>
    
    
  )
}

export default Home
