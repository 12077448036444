import React, {useState} from 'react'
import './Login.css'
import { Link, useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert';
import { bindActionCreators } from 'redux';
import {actionCreators} from './state/index'
import { useDispatch, useSelector } from 'react-redux';

function Login() {

  const logSetter=useDispatch();
  const action=bindActionCreators(actionCreators,logSetter);
  const authSetter=useDispatch();
  const token=bindActionCreators(actionCreators,authSetter);
  const tokenState= useSelector(state=>state.authToken)

    const navigate=useNavigate();
    const [data,setData]=useState({"email":"","password":""})
    //for alerts
    const [status,setStatus]=useState("");
    const [confirm,setConfirm]=useState(true)

    const login = async (email, password) => {
    const response = await fetch("https://backend-j6gf.onrender.com/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
         },
        
        body: JSON.stringify({ email,password}),
      });
      const json= await response.json();
      if(!json.success)
      {
          setStatus(json.message);
          setConfirm(false);
  
          setTimeout(() => {
            setConfirm(true);
          }, 1000);

      }else{
          setConfirm(true);
          token.setAuthToken(json.authToken);
          action.loginChecker(true);
          navigate("/");
      }    
    };
  
  const onchange=(e)=>{
      setData({...data,[e.target.name]:e.target.value})       
  }

  const submit=(e)=>{
      e.preventDefault();
      login(data.email,data.password);   

  }




  return (
    <>
 {!confirm?<Alert className='alrt' variant="filled" severity="error">
        <p>{status}</p>
      </Alert>:<></>}
     <div className='OutestDivl'>
      <form className="form_mainl" onSubmit={submit} action="">
    <p className="headingl">Login</p>
    <div className="inputContainerl">
        
       
        <input placeholder="Email" id="email" name='email' className="inputFieldl" type="email" onChange={onchange} required/>
        </div>
    
        <div className="inputContainer">
            
            
            <input placeholder="Password" id="password" name='password' className="inputFieldl" type="password" onChange={onchange} minLength={5} required/>
        </div>
        
           
        <button id="buttonl" >Submit</button>
            <div className="signupContainerl">
                <p>Don't have any account?</p>
                <Link to="/signup"><button id='btn' className='btn btn-info'><span id='span'>Sign up</span></button></Link>
            </div>
        </form>

    </div>
    </>
  )
}

export default Login
