import './ProductsA.css'
import Rating from '@mui/material/Rating';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {actionCreators} from './state/index'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import { useState } from 'react';

function ProductsA(props) {

  const nameSetter=useDispatch();
  const name=bindActionCreators(actionCreators,nameSetter);
  const priceSetter=useDispatch();
  const price=bindActionCreators(actionCreators,priceSetter);
  const imageSetter=useDispatch();
  const image=bindActionCreators(actionCreators,imageSetter);
  const norSetter=useDispatch();
  const nor=bindActionCreators(actionCreators,norSetter);
  const ratingSetter=useDispatch();
  const rating=bindActionCreators(actionCreators,ratingSetter);
  const ctgSetter=useDispatch();
  const category=bindActionCreators(actionCreators,ctgSetter);
  const idSetter=useDispatch();
  const id=bindActionCreators(actionCreators,idSetter);
    const nm=useSelector(state=>state.name);
    const pr=useSelector(state=>state.price);
    const ct=useSelector(state=>state.ctg);
    const token=useSelector(state=>state.authTokenA);
    const ID=useSelector(state=>state.id);
    
    const [edit,setEdit]=useState({"name":nm,"price":pr,"category":ct})



  const setState=()=>{
    name.setName(props.name);
    price.setPrice(props.price);
    image.setImage(props.image);
    nor.setNOR(props.numberOfRatingsGiven);
    rating.setrating(props.AggregatedRating.toFixed(1));
    category.setctg(props.category);
    id.setID(props._id);
  }


  const signup = async (name,price,category) => {
      const response = await fetch(`https://backend-j6gf.onrender.com/api/products/editProduct/${ID}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token":token
         },
       
        body: JSON.stringify({ name,price,category}),
      });
  };


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const onchang=(e)=>{
    setEdit({...edit,[e.target.name]:e.target.value})
    
  }

  const update=()=>{
    handleShow();

  }
  return (
    <>
    <Modal className='modl' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
        <Form.Group className="mb-3 mx-5" >
          <Form.Label>Name</Form.Label>
          <Form.Control className='modl-inp' type="text" id="name" name="name" placeholder="Name" defaultValue={nm} onChange={onchang}/>
        </Form.Group>

        <Form.Group className="mb-3 mx-5">
          <Form.Label>Price</Form.Label>
          <Form.Control type="number"  className='modl-inp' placeholder="Price" defaultValue={pr} name="price" id="price" onChange={onchang} />
        </Form.Group>

        <Form.Group className="mb-3 mx-5">
          <Form.Label>Category</Form.Label>
          <Form.Control type="text" placeholder="Category"  className='modl-inp' defaultValue={ct} name="category" id="category" onChange={onchang} />
        </Form.Group>
      </Form>
      </Modal.Body>
        <Modal.Footer>
          <Button className='modl-inp' variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button className='modl-inp' variant="primary" onClick={()=>{signup(edit.name,edit.price,edit.category);handleClose();}}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    <Link  style={{textDecoration:'none'}}>
    <div>
          <div class="card mx-4">
          <div class="card-img">
            <img className='img' src={require(`../Product images/${props.image[0]}`)} alt="" ></img>
          </div>
          <div class="card-info">
            <p class="text-title">{props.name}<i onClick={()=>{setState();update();}} class="mx-3 fa-solid fa-pen-to-square"></i></p>
            <div className='ratings' ><Rating className='stars' defaultValue={props.AggregatedRating.toFixed(1)} precision={0.1} readOnly /><span className='nor'>{props.AggregatedRating.toFixed(1)     }</span></div>
          </div>
          <div class="card-footer">
          <span class="text-title2">{`Rs ${props.price}`}</span>
        </div>
        </div>
    </div>
    </Link>
    </>
  )
}

export default ProductsA;
