import './ChangePassword.css'
import React, {useState } from 'react'
import {useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert';
import { useSelector } from 'react-redux';

function ChangePassword() {
  
    const token=useSelector(state=>state.authToken)
    const navigate=useNavigate();
    const [data,setData]=useState({"currentPassword":"","newPassword":""})
    const [status,setStatus]=useState([]);
    const [confirm,setConfirm]=useState(true)


    const signup = async (currentPassword,newPassword) => {
        const response = await fetch("https://backend-j6gf.onrender.com/api/auth/changePassword", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "auth-token":token
           },
         
          body: JSON.stringify({ currentPassword,newPassword}),
        });
        const json= await response.json();
      
      if(!json.success)
      {
          // setStatus(json.message);
          setConfirm(false);
          
          setTimeout(() => {
            setConfirm(true);
          }, 1500);


      }else{
        setConfirm(true);
        navigate("/");
      }    
    };
  
  const onchange=(e)=>{
      setData({...data,[e.target.name]:e.target.value})  
  }

  const submit=(e)=>{
      e.preventDefault();
      signup(data.currentPassword,data.newPassword);        
  }



  return (
    <>
    {!confirm?<Alert className='alert' variant="filled" severity="error">
        {status}
      </Alert>:<></>}
        <div className='OutestDiv'>
        <form className="form_main" onSubmit={submit} action="">
        <p className="heading">Password Change</p>
        <div className="inputContainer">
        
       
        <input placeholder="Old Password"  id="currentPassword" name='currentPassword'   className="inputField" onChange={onchange}  type="password" required/>
        </div>
    
        <div className="inputContainer">
            
            
            <input placeholder="New Password" id="newPassword" name='newPassword' className="inputField" type="password" onChange={onchange} minLength={5} required/>
        </div>
              
        <button id="button" className='button' >Submit</button>
            
        </form>

    </div>
    </>
  )
}

export default ChangePassword
