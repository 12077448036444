import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { bindActionCreators } from 'redux';
import {actionCreators} from './state/index'
import { useDispatch } from 'react-redux';
import {useNavigate } from 'react-router-dom'

function Navb() {

  
  const navigate=useNavigate();
   const logInfo= useSelector(state=>state.log);
   const orders=useSelector(state=>state.orders);
   const tokenState= useSelector(state=>state.authToken)
   const logSetter=useDispatch();
   const action=bindActionCreators(actionCreators,logSetter);
   const authSetter=useDispatch();
   const tokenSet=bindActionCreators(actionCreators,authSetter);

   const logout = async (token) => {
    const response = await fetch("https://backend-j6gf.onrender.com/api/auth/logout", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        'auth-token':tokenState,
       },
      body: JSON.stringify(),
    });

    const json= await response.json();
    
   
  if(json.success)
  {
    tokenSet.setAuthToken("");
    action.loginChecker(false);
    navigate("/");
  }
};

   const onClick=()=>{
    logout(tokenState);
   }

  return (
    <div>
        <Navbar fixed="top" collapseOnSelect expand="xl" className="navbar" data-bs-theme="dark">
        <Container>
          <Navbar.Brand className='brandName' >Picture Says It All</Navbar.Brand>
          <Navbar.Toggle className="hamburge" aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="paths">
           
              <Link  className='path1' to={'/'}>Home</Link>
              {!logInfo?<><Link  className='path2' to={'/contactUs'} >Contact us</Link> <Link  className='path2' to={'/AboutUs'} >About</Link><Link className="signIn btn btn-info" to={'/login'} >Sign in</Link></>:<> <i onClick={()=>{navigate('/cart')}} className="ico fa-solid fa-lg fa-cart-shopping"></i><NavDropdown title="My Account"  className='category' style={{color:'white'}} id="collapsible-nav-dropdown">
                <NavDropdown.Item as={Link} to={'/changePassword'} className='c1' >Change password</NavDropdown.Item>
                <NavDropdown.Item className='c2' ><Link><button className="c2 btn btn-danger" onClick={onClick}>Log out</button></Link></NavDropdown.Item>
              </NavDropdown>
              <Link className='path2' to={'/OrdersStatus'}>Orders Status</Link>
              <Link  className='path2' to={'/contactUs'} >Contact us</Link>
              <Link  className='path2' to={'/AboutUs'} >About</Link>
              </>}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navb;
