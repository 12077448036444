import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./NavbA.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { bindActionCreators } from 'redux';
import {actionCreators} from './state/index'
import { useDispatch } from 'react-redux';
import {useNavigate } from 'react-router-dom'

function NavbA() {

  
  const navigate=useNavigate();
   const logInfo= useSelector(state=>state.logA);
   const tokenState= useSelector(state=>state.authTokenA)
   const logSetter=useDispatch();
   const action=bindActionCreators(actionCreators,logSetter);
   const authSetter=useDispatch();
   const tokenSet=bindActionCreators(actionCreators,authSetter);

   const logout = async (token) => {
    const response = await fetch("https://backend-j6gf.onrender.com/api/AdminAuthentication/logoutAdmin", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        'auth-token':token,
       },
      body: JSON.stringify(),
    });

    const json= await response.json();

  if(json.success)
  {
    tokenSet.setAuthTokenA("");
    action.loginCheckerA(false);
    navigate("/");
  }
};

   const onClick=()=>{
    logout(tokenState);
   }

  return (
    <div>
        <Navbar fixed="top" collapseOnSelect expand="xl" className="navbar" data-bs-theme="dark">
        <Container>
          <Navbar.Brand className='brandName' >Picture Says It All</Navbar.Brand>
          <Navbar.Toggle className="hamburger" aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="paths">
              <Link  className='path1' to={'/AdminPortal'}>Home</Link>
              <Link  className='path2' to={'/payments'} >Payments</Link>
              <Link  className='path2' to={'/ordersFetching'}>Orders</Link>
              <NavDropdown title="My Account"  className='category' style={{color:'white'}} id="collapsible-nav-dropdown">
                <NavDropdown.Item className='c2' ><Link><button className="c2 btn btn-danger" onClick={onClick}>Log out</button></Link></NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavbA;
