import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
function Footer() {
  return (
    <div className='footer'>
    <div className='foot'>
      <div className='info  my-4'>
        <h3 className='headingssss'>Information</h3>
        <div className='infos'>
            <Link className='link' style={{textDecoration:'none',fontFamily:'Roboto',color:'white'}} to={'/privacyPolicy'}>Privacy Policy</Link>
            <Link className='link' style={{textDecoration:'none',fontFamily:'Roboto',color:'white'}} to={'/returnPolicy'}>Return Policy</Link>
            <Link className='link'  style={{textDecoration:'none',fontFamily:'Roboto',color:'white'}} to={'/contactUs'}>Contact us</Link>
            <Link className='link'  style={{textDecoration:'none',fontFamily:'Roboto',color:'white'}} to={'/AboutUs'}>About us</Link>
        </div>
      </div>
      <div className='info  my-4 '>
        <h3 className='headingssss'>Customer Services</h3>
        <div className='infos'>
            <Link className='link'  style={{textDecoration:'none',fontFamily:'Roboto',color:'white'}} to={'/OrdersStatus'}>Track your order</Link>
            <Link  className='link' style={{textDecoration:'none',fontFamily:'Roboto',color:'white'}} to={'/cart'}>Your Cart</Link>
            
        </div>
      </div>
      <div className='info  my-4 '>
        <h3 className='headingssss'>Follow us</h3>
        <div className='infos '>
            <Link className='link' target='_blank'  style={{textDecoration:'none',fontFamily:'Roboto',color:'white'}} to={'https://www.instagram.com/picture.says.it.all?igsh=MTl4bWlla3ZldTRmeA%3D%3D&utm_source=qr'}>Instagram</Link>
            <Link className='link'target='_blank'  style={{textDecoration:'none',fontFamily:'Roboto',color:'white'}} to={'https://pin.it/3Cs6tNqdP'}>Pinterest</Link>
            <Link className='link'target='_blank'  style={{textDecoration:'none',fontFamily:'Roboto',color:'white'}} to={'https://www.tiktok.com/@picture.says.it.all?_t=8k2Et5ip2Vs&_r=1'}>Tiktok</Link>
            <Link className='link' target='_blank' style={{textDecoration:'none',fontFamily:'Roboto',color:'white'}} to={'https://www.facebook.com/profile.php?id=61550644457286&mibextid=kFxxJD'}>Facebook</Link>
        </div>
      </div>
    </div>
    <div className='portfolio'>
        <h3 className='head' style={{fontFamily:'Roboto'}}>Powered by</h3>
        <Link className='Mateen' style={{fontFamily:'Roboto',textDecoration:'none',color:'white'}} to={'#'}>Muhammad Mateen Amjad</Link>
    </div>
    </div>
  )
}

export default Footer
